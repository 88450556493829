<template>
     <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal" :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">APPLY COUPON CODE</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #fff;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0" style="max-height: 400px !important;overflow-x: hidden !important;overflow-y: auto !important;">
                    <div class="v-modal-layout pr-3">
                        <div>
                            <span class="text-secondary font-weight-bold">Enter Coupon Code </span>
                            <div class="d-flex applyinput mt-3 mb-5">
                                <input type="text" placeholder="Enter Coupon Code" v-model="promotion_code">
                                <button class="btn btn-apply" :disabled="promotion_code.length == 0" @click="applyPromotion()">Apply</button>
                            </div>
                            <span class="font-weight-bold fs-18">AVAILABLE COUPONS</span>
                            <div class="row justify-content-center" v-if="ajaxcall">
                                <div class="digi-table-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                </div>
                            </div>
                            <div v-if="!ajaxcall && allpromotions.length == 0" class="text-center mt-5 mb-5">
                                <span class="text-center font-weight-bold">No Active Promotions Found</span>
                            </div>
                            <div class="d-flex mt-4 mb-5 coupondetails" v-for="(coupon,index) in allpromotions" :key="index">
                                <div class="col-md-8 col-sm-7 mt-3 mb-3" style="border-right: 3px solid rgb(192, 196, 204);">
                                    <div class="pb-4 font-600">
                                        {{coupon.description}}
                                    </div>
                                    <span>
                                        <i class="icon icon-clock clockcolor"></i>
                                        {{coupon.end_date}}
                                    </span>
                                </div>
                                <div class="col-md-4 col-sm-5 text-center mt-5 mb-3">
                                    <span class="dashedCode">{{coupon.code}}</span>
                                    <button class="btn btn-coupon mt-4" :disabled="!coupon.apply_flag" @click="selected(coupon)">APPLY COUPON</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="v-modal-footer text-center pt-5">
                    <a class="btn btn-link btn-smm" @click="cancel">CANCEL</a>
                    <button class="btn btn-secondary btn-smm text-uppercase">SAVE</button>
                </div> -->
            </div>
        </modal>
        <offer-applicable-products modal_name="applicable-products" :storeorderId="storeorderId" @buyUpdated="buyUpdated()"></offer-applicable-products>
        <offer-applicable-get modal_name="applicable-get" :storeorderId="storeorderId" @getUpdated="getUpdated()"></offer-applicable-get>
     </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event';
const OfferApplicableProducts = () => import('./OfferApplicableProducts')
const OfferApplicableGet = () => import('./OfferApplicableGet')
export default {
    props:['modal_name','storeorderId','source'],
    data(){
        return{
            allpromotions:[],
            promotion_code:'',
            ajaxcall:false,
            safariTrue:false
        }
    },
    components:{
        OfferApplicableProducts,
        OfferApplicableGet
    },
    methods:{
        cancel(){
            if(this.promotion_code.length){
                this.$emit('promotionapplied')
            }
            this.allpromotions = []
            this.promotion_code = ''
            this.$modal.hide(this.modal_name)
            if(this.source == 'paybill'){
                this.$emit('promotionshowbill')
            }
        },
        getUpdated(){
            this.$modal.hide('applicable-get')
            this.cancel()
        },
        buyUpdated(){
            this.$modal.hide('applicable-products')
            this.cancel()
        },
        selected(coupon){
            this.promotion_code = ''
            this.promotion_code = coupon.code
            this.applyPromotion()
        },
        async applyPromotion(){
            try {
                let params ={
                    order_id:this.storeorderId,
                    promotion_code:this.promotion_code
                }
                params.promotion_code = params.promotion_code.toUpperCase();
                let res = await this.$http.post('/promotions/apply_promotion',params)
                if(res.data.status_id == 1){
                    if(res.data.response.goto == 'checkout'){
                        this.$swal({
                            imageUrl:'https://cdn.digicollect.com/cdn/posv2/Dog3.svg',
                            title: 'Yo Baby !!!',
                            text: 'Coupon Applied Successfully',
                            showConfirmButton:false,
                            timer: 1000
                        })
                        this.cancel()
                    }
                    if(res.data.response.goto == 'select_buy_products'){
                        this.showapplicableproducts()
                    }
                    if(res.data.response.goto == 'select_get_products'){
                        this.showapplicableproductsGet()
                    }
                }else{
                    this.$swal({
                        imageUrl:'https://cdn.digicollect.com/cdn/posv2/InvalidCoupon.svg',
                        title: 'Hooman',
                        text: 'This is not working!!',
                        showConfirmButton:false,
                        timer: 1000
                    })
                }
            } catch (error) {

            }
        },
        showapplicableproducts(){
            let params={
                order_id:this.storeorderId
            }
            EventBus.$emit('detailproduct',params)
            this.$modal.hide(this.modal_name)
            this.$modal.show('applicable-products')
        },
        showapplicableproductsGet(){
            EventBus.$emit('detailproductget')
            this.$modal.hide(this.modal_name)
            this.$modal.show('applicable-get')
        }
    },
    mounted(){
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        EventBus.$on('showactive',params=>{
            this.ajaxcall = true
            this.$http.post('/promotions/get_all_active_promotions',params).then(res=>{
                if(res.data.status_id == 1){
                    this.allpromotions = res.data.response.active_promotions
                    this.ajaxcall = false
                }else{
                    this.$toasted.global.error(res.data.reason);
                    this.cancel()
                }
            })
        })
    }
}
</script>
<style scoped>
.clockcolor{
    color: #f5a623 !important;
    font-size: 14px !important;
}
.btn-apply{
    background: #f5a623 !important;
    color: #303031;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.btn-coupon{
    background: #00448b !important;
    color: #fff !important;
    font-weight: 600;
    border-radius: 8px !important;
}
.applyinput input{
    width: 550px !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    -moz-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    box-shadow:0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    text-transform: uppercase !important;
    font-weight: 600;
}
.applyinput input ::placeholder{
    font-weight: 500;
    color: #303031 !important;
}
.applyinput {
    padding-bottom: 15px;
    border-bottom: 2px dashed #00448b;
}
.dashedCode{
    border: 1px dashed #707070;
    padding: 5px 20px !important;
    font-weight: 600;
    text-transform: uppercase !important;
}
.font-600{
    font-weight: 600 !important;
}
.coupondetails{
    -moz-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    box-shadow:0px 3px 0px 0px rgba(0, 0, 0, 0.16) !important;
    background-color: #eff1f4;
    border-radius: 8px !important;
}
</style>